import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slide2 from './Slide2';
import Slide3 from './Slide3';
import Slide4 from './Slide4';
import "./index.css";  

const IgtHomeSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
        /*autoplay: true,
        autoplaySpeed: 5000,*/
    };

    return (
        <div>
            <div className="homePageSlider">
                <Slider {...settings}>
                    <div>
                        <Slide2 />
                    </div>
                    <div>
                        <Slide3 />
                    </div>

                </Slider>
            </div>
        </div>
    )
}

export default IgtHomeSlider

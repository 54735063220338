import { Link } from 'react-router-dom';
import { base_url_url } from '../igtComponant/Links'
import React from "react";
import {Helmet} from "react-helmet";
import { useEffect } from 'react';

const PageNotFound = () => {

  useEffect(()=>{
    document.body.className += ' page-not-found';
  },[]);

  return (
    <>
      <Helmet>
          <title>Page Not Found | IGTechso </title>
      </Helmet>  
      <div className="container">
        <div className="page-not-found">
          <div className="row">
              <div className="image-1">
                <img src={`${base_url_url}man.png`} alt=""/>
              </div>
              <div className="content-section">
                <h1>404</h1>
                <p>
                  <em>opps!</em> SOmetning went wrong.
                  Looks Like the Link is broken or page has been removed
                </p>
                <Link to="/">Goto Homepage</Link>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageNotFound;

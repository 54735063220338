import { base_url_url } from '../Links';
import { Link } from 'react-router-dom';
import { SahpeOne, ShapeTwo, ShapeThree, ShapeFour, ShapeFive, ShapeSix } from '../Shape/ServicesShape';
  
const ServicesSection = () =>{
    const myStyle= {
        backgroundImage: `url("${base_url_url}shape-2.png")`,
    };
    return(
        <>
            <section className="services" style={myStyle}>
                <div className="container">
                    <h3 data-aos="fade-up" data-aos-once="true">OUR SERVICES</h3>
                    <h2 data-aos="fade-up" data-aos-once="true">We provide wide range of web &< br/>business services.</h2>
                    {/*
                    <div className='service-section'>
                        <div className='service-box-1' data-aos="fade-up" data-aos-once="true">
                            <img src={`${base_url_url}mern.png`} />
                            <Link to="/">MERN PROJECT</Link>
                            <p>We design stunning websites delivered on purpose, on time, with results. It’s made us one of the most sought-after web design agencies in World.</p>
                        </div>

                        <div className='service-box-1' data-aos="fade-up" data-aos-once="true">
                            <img src={`${base_url_url}shop.png`} />
                            <Link to="/">MEAN PROJECT</Link>
                            <p>We build eCommerce solutions that are fast, robust and easy to use. Sell with confidence online with a custom designed e-commerce website.</p>
                        </div>

                        <div className='service-box-1' data-aos="fade-up" data-aos-once="true">
                            <img src={`${base_url_url}coding.png`} />
                            <Link to="/">PHP + API</Link>
                            <p>We deliver cutting-edge web development for projects of all descriptions. Our developers are experts in their field.</p>
                        </div>

                        <div className='service-box-1' data-aos="fade-up" data-aos-once="true">
                            <img src={`${base_url_url}logo-design.png`} />
                            <Link to="/">PHP BASED CMS</Link>
                            <p>Your brand is so important! Top quality design delivered with style, simplicity, and purpose. Work with us on your next logo design project.</p>
                        </div> 
                    </div>  

                    <div className='news-services'>
                        
                        <div className='service-section-2' id="s1" data-aos-delay="0" data-aos="fade-up" data-aos-once="true">
                            <SahpeOne />
                            <div className='content-service'>
                                <Link to="/services">
                                    <img src={`${base_url_url}mern.png`} alt="Mern Project Development" title="" width="auto" height="auto"/>
                                    <h3>Mern Project Development</h3>
                                    <p>We design stunning websites delivered on purpose, on time, with results. It’s made us one of the most sought-after web design agencies in World.</p>
                                </Link>
                            </div>
                        </div> 

                        <div className='service-section-2' id="s2" data-aos-delay="300" data-aos="fade-up" data-aos-once="true">
                            <ShapeTwo />
                            <div className='content-service'>
                                <Link to="/services">
                                    <img src={`${base_url_url}mean.png`} alt="Mean Project Development" title="" width="auto" height="auto"/>
                                    <h3>Mean Project Development</h3>
                                    <p>We build eCommerce solutions that are fast, robust and easy to use. Sell with confidence online with a custom designed e-commerce website.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s3" data-aos-delay="600" data-aos="fade-up" data-aos-once="true">
                            <ShapeThree />
                            <div className='content-service'>
                                <Link to="/services">
                                    <img src={`${base_url_url}php-api.png`} alt="PHP + API" title="" width="auto" height="auto"/>
                                    <h3>PHP + API Development</h3> 
                                    <p>We deliver cutting-edge web development for projects of all descriptions. Our developers are experts in their field.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s4" data-aos-delay="0" data-aos="fade-up" data-aos-once="true">
                            <ShapeFour />
                            <div className='content-service'>
                                <Link to="/services">
                                    <img src={`${base_url_url}cms.png`} alt="PHP Based CMS Development" title="" width="auto" height="auto"/>
                                    <h3>PHP Based CMS Development</h3>
                                    <p>Your brand is so important! Top quality design delivered with style, simplicity, and purpose. Work with us on your next logo design project.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s4" data-aos-delay="300" data-aos="fade-up" data-aos-once="true">
                            <ShapeFive />
                            <div className='content-service'>
                                <Link to="/services">
                                    <img src={`${base_url_url}react.png`} alt="React Native" title="" width="auto" height="auto"/>
                                    <h3>React Native Development</h3>
                                    <p>Your brand is so important! Top quality design delivered with style, simplicity, and purpose. Work with us on your next logo design project.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s4" data-aos-delay="600" data-aos="fade-up" data-aos-once="true">
                            <ShapeSix />
                            <div className='content-service'>
                                <Link to="/services">
                                    <img src={`${base_url_url}ios.png`} alt="iOS App Development" title="" width="auto" height="auto"/>
                                    <h3 className='last-child'>iOS App Development</h3>
                                    <p>Your brand is so important! Top quality design delivered with style, simplicity, and purpose. Work with us on your next logo design project.</p>
                                </Link>
                            </div>
                        </div>
                    </div> */}

                    <div className='services-201'>
                        <div className='inner-services' data-aos="fade-up" data-aos-once="true" data-aos-delay="0">
                            <div className='servicesInfoBox'>
                                <img src={`${base_url_url}mern-services.png`}  alt="" title='' width="" height=""/>
                                <h3>MERN Project <br />Development</h3>
                            </div>
                        </div>
                        
                        <div className='inner-services' data-aos="fade-up" data-aos-once="true" data-aos-delay="300">
                            <div className='servicesInfoBox'>
                                <img src={`${base_url_url}mean-services.png`} alt="" title='' width="" height=""/>
                                <h3>MEAN Project <br />Development</h3>
                            </div>
                        </div>

                        <div className='inner-services' data-aos="fade-up" data-aos-once="true" data-aos-delay="600">
                            <div className='servicesInfoBox'>
                                <img src={`${base_url_url}php-based-api.png`} alt="" title='' width="" height=""/>  
                                <h3>PHP + API<br /> Development</h3>
                            </div>
                        </div>

                        <div className='inner-services' data-aos="fade-up" data-aos-once="true" data-aos-delay="0">
                            <div className='servicesInfoBox'>
                                <img src={`${base_url_url}php-based-cms.png`} alt="" title='' width="" height=""/>
                                <h3>Php Based CMS <br />Development</h3>
                            </div>
                        </div>

                        <div className='inner-services' data-aos="fade-up" data-aos-once="true" data-aos-delay="300">
                            <div className='servicesInfoBox'>
                                <img src={`${base_url_url}react-natives.png`} alt="" title='' width="" height=""/>                                
                                <h3>React Native <br />Development</h3>
                            </div>
                        </div>

                        <div className='inner-services' data-aos="fade-up" data-aos-once="true" data-aos-delay="600">
                            <div className='servicesInfoBox'>
                                <img src={`${base_url_url}ios-app-development.png`} alt="" title='' width="" height=""/>
                                <h3><span style={{"textTransform":"lowercase"}}>i</span>OS App <br/>Development</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ServicesSection;
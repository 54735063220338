import { base_url_url } from "../igtComponant/Links";
import {
  ServiceShapeOne,
  ServiceShapeTwo,
  ServiceShapeThree,
  ServiceShapeFour,
  ServiceShapeFive,
  ServiceShapeFix,
} from "../igtComponant/Shape/ShapeServicePage";
import { Link } from "react-router-dom";
import "../services.css";
import { Helmet } from "react-helmet";

const Servicces = () => {
  return (
    <>
      <Helmet>
        <title>Services | IGTechso </title>
        <link
          rel="canonical"
          href={`${process.env.PUBLIC_URL}/services`}
        ></link>
        <meta name="description" content="IGT" />
        <meta name="keywords" content="IGT" />
        <meta name="author" content="IGT" />
      </Helmet>

      <section className="services-header">
        <div className="bg">
          <img
            src={`${base_url_url}service-bottom-left.png`}
            alt="Background Layer"
            title="Background Layer"
            width="auto"
            height="auto"
            className="bottom-left"
          />
          <img
            src={`${base_url_url}service-top-right.png`}
            alt="Background Layer"
            title="Background Layer"
            width="auto"
            height="auto"
            className="top-right"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="service_content-section">
              <h2>Our IT Services</h2>
              <div className="service_content_box">
                <p>
                  At IGTechso, we specialize in delivering customized IT
                  solutions that cater to the unique needs of your business.
                  It's important to understand various IT service models to make
                  the best choice for your company. You can read more about the
                  comparison between 
                  <Link
                    to="https://www.designrush.com/agency/it-services/trends/staff-augmentation-vs-managed-services"
                    target="blank"
                  > staff augmentation and managed services </Link>
                   to gain insights into different approaches. Explore our
                  services to see how we can support your technology
                  requirements.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*
            <section className="servicesGridSection">
                <div className="container">
                <div className='news-services'>
                        
                        <div className='service-section-2' id="s1">
                            <ServiceShapeOne />
                            <div className='content-service'>
                                <Link to="/">
                                    <img src={`${base_url_url}mern.png`} alt="Mern Project Development" title="Mern Project Development" width="auto" height="auto"/>
                                    <h3>Mern Project Development</h3>
                                    <p>We design stunning websites delivered on purpose, on time, with results. It’s made us one of the most sought-after web design agencies in World.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s2">
                            <ServiceShapeTwo />
                            <div className='content-service'>
                                <Link to="/">
                                    <img src={`${base_url_url}mean.png`} alt="Mean Project Development" title="Mean Project Development" width="auto" height="auto"/>
                                    <h3>Mean Project Development</h3>
                                    <p>We build eCommerce solutions that are fast, robust and easy to use. Sell with confidence online with a custom designed e-commerce website.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s3">
                            <ServiceShapeThree />
                            <div className='content-service'>
                                <Link to="/">
                                    <img src={`${base_url_url}php-api.png`} alt="PHP + API Development" title="PHP + API Development" width="auto" height="auto"/>
                                    <h3>PHP + API Development</h3> 
                                    <p>We deliver cutting-edge web development for projects of all descriptions. Our developers are experts in their field.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s4">
                            <ServiceShapeFour />
                            <div className='content-service'>
                                <Link to="/">
                                    <img src={`${base_url_url}cms.png`} alt="PHP Based CMS Development" title="PHP Based CMS Development" width="auto" height="auto"/>
                                    <h3>PHP Based CMS Development</h3>
                                    <p>Your brand is so important! Top quality design delivered with style, simplicity, and purpose. Work with us on your next logo design project.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s4">
                            <ServiceShapeFive />
                            <div className='content-service'>
                                <Link to="/">
                                    <img src={`${base_url_url}react.png`} alt="React Native Development" title="React Native Development" width="auto" height="auto"/>
                                    <h3>React Native Development</h3>
                                    <p>Your brand is so important! Top quality design delivered with style, simplicity, and purpose. Work with us on your next logo design project.</p>
                                </Link>
                            </div>
                        </div>

                        <div className='service-section-2' id="s4">
                            <ServiceShapeFix />
                            <div className='content-service'>
                                <Link to="/">
                                    <img src={`${base_url_url}ios.png`} alt="iOS App Development" title="iOS App Development" width="auto" height="auto"/>
                                    <h3>iOS App Development</h3>
                                    <p>Your brand is so important! Top quality design delivered with style, simplicity, and purpose. Work with us on your next logo design project.</p>
                                </Link>
                            </div>
                        </div>

                    

                    

                    
                    </div>
                </div>
            </section>
             */}
      <section className="servicesGridSection">
        <div className="container">
          <div className="services-201">
            <div
              className="inner-services"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="0"
            >
              <div className="servicesInfoBox">
                <img
                  src={`${base_url_url}mern-services.png`}
                  alt=""
                  title=""
                  width=""
                  height=""
                />
                <h3>
                  MERN Project <br />
                  Development
                </h3>
              </div>
            </div>

            <div
              className="inner-services"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="300"
            >
              <div className="servicesInfoBox">
                <img
                  src={`${base_url_url}mean-services.png`}
                  alt=""
                  title=""
                  width=""
                  height=""
                />
                <h3>
                  MEAN Project <br />
                  Development
                </h3>
              </div>
            </div>

            <div
              className="inner-services"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="600"
            >
              <div className="servicesInfoBox">
                <img
                  src={`${base_url_url}php-based-api.png`}
                  alt=""
                  title=""
                  width=""
                  height=""
                />
                <h3>
                  PHP + API
                  <br /> Development
                </h3>
              </div>
            </div>

            <div
              className="inner-services"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="0"
            >
              <div className="servicesInfoBox">
                <img
                  src={`${base_url_url}php-based-cms.png`}
                  alt=""
                  title=""
                  width=""
                  height=""
                />
                <h3>
                  Php Based CMS <br />
                  Development
                </h3>
              </div>
            </div>

            <div
              className="inner-services"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="300"
            >
              <div className="servicesInfoBox">
                <img
                  src={`${base_url_url}react-natives.png`}
                  alt=""
                  title=""
                  width=""
                  height=""
                />
                <h3>
                  React Native <br />
                  Development
                </h3>
              </div>
            </div>

            <div
              className="inner-services"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="600"
            >
              <div className="servicesInfoBox">
                <img
                  src={`${base_url_url}ios-app-development.png`}
                  alt=""
                  title=""
                  width=""
                  height=""
                />
                <h3>
                  <span style={{ textTransform: "lowercase" }}>i</span>OS App
                  <br />
                  Development
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Servicces;

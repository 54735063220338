import { base_url_url } from './Links';
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialSlider = () => {
  const setting = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  }; 

  return (
    <div className="testimonial-slider">
      <Slider {...setting}> 
        <div>
            <div className="testimonial-item">
                <q>Great work. Delivered to exact design specification. Really great to work with. Would definitely use again.</q>
                <div className="testimonial-bottom-area">
                  <img src={`${base_url_url}test-user.png`} alt="" title="" width="auto" height="auto" />  <strong>Jonathan Simpson</strong>
                    Manager
                </div>
            </div>
        </div>
        <div>
            <div className="testimonial-item">
                <q>This was my first project. I had no experience in developing any project. IGTechso was very helpful, passion and professional. I recommend him for your projects. Thank you.</q>
                <div className="testimonial-bottom-area">
                  <img src={`${base_url_url}test-user.png`} alt="" title="" width="auto" height="auto" />  <strong>Angelina Johnson</strong>
                    Manager
                </div>
            </div>
        </div>
        <div>
            <div className="testimonial-item">
                <q>Very professional, highly recommended, this is the second project that we have done with the team, same high standard as the first</q>
                <div className="testimonial-bottom-area">
                  <img src={`${base_url_url}test-user.png`} alt="" title="" width="auto" height="auto" />  <strong>Gabriel Townsend</strong>
                  Manager
                </div>
            </div>
        </div>
        
      </Slider>
    </div>
  );
};

export default TestimonialSlider;

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "./style.css";
import App from "./App";

const rootElement = document.getElementById("root");
// ReactDOM.hydrate(<App />,
//   rootElement
// );

var renderMethod;
if (rootElement && rootElement.innerHTML !== "") {
  renderMethod = ReactDOM.hydrate
} else {
  renderMethod = ReactDOM.render
}
renderMethod(<App />, rootElement)

/*
ReactDOM.render(<App />,
  rootElement
);
*/

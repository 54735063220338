import { Link } from 'react-router-dom';
import { base_url_url } from '../Links';
import BackgroundLayer from '../BackgroundLayer';
const About = () =>{
    return(
        <>
        <section id="about-us">
            <BackgroundLayer />
            <div className="container">
               <div className='row'>
                   <div className='image-section' data-aos="fade-right" data-aos-once="true"> <img src={`${base_url_url}illustration.png`} alt="This is why you should hire us!" title="This is why you should hire us!" width="590" height="auto"/></div>
                   <div className='content-section'>
                       <h2 data-aos="fade-left" data-aos-once="true">This is why you should <br/>hire us!</h2>
                       <p data-aos="fade-left" data-aos-once="true">
                       We have over ten years experience in the web design industry designing and developing more than 5000 websites of all different sizes and complexity. During this time we have successfully refined and honed our skills, pinpointing the essence of what makes a great website design. We have identified four key components, and in this article, we explain the significance of each.
                       </p>
                       <Link to="/about">
                           <div className='more-about-us' data-aos="fade-left" data-aos-once="true">
                            <span>Learn More</span>
                            <span>More About Us <i className='fa fa-fa-long-arrow-right'></i></span>
                           </div>
                       </Link>
                   </div>
                </div> 
            </div>
        </section>
        </>
    )
}

export default About;
import React from 'react'
import './Slide2.css'; 
import { base_url_url } from '../Links';
import {Link} from 'react-router-dom'

const Slide3 = () => {
    return (
        <div className='animatedSlider-wrapper slide-3' id="slide-3">
        <div className='sliderBanner'>
           <img src={`${base_url_url}banner-102.png`} className='banner-1' alt="" title="" width="auto" height="auto"/>    
           <img src={`${base_url_url}banner-101.png`} className='banner-2' alt="" title="" width="auto" height="auto"/>    
        </div>
        <div className='container'>
            <div className='slider-box'>
                <div>
                    <img src={`${base_url_url}mern_zazz.png`} alt="" title="" width="auto" height="auto"/>    
                </div>
                <div>
                    <h2>MERN Stack Development</h2>
                    <p>We at IGTechso have a dedicated process which'll help you with step by step website development and project management.</p>
                    <a className="btn-1" href="/portfolio"><span></span><span></span><span></span>Explore More</a>
                </div>
                
            </div>
        </div>
    </div>
    )
} 

export default Slide3

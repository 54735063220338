import HomeSlider from "../igtComponant/HomeSlider";
import IgtHomeSlider from "../igtComponant/IgtHomeSlider";
import Process from '../igtComponant/HomePage/Process'
import AboutUs from '../igtComponant/HomePage/AboutUs'
import ServicesSection from '../igtComponant/HomePage/ServicesSection';
import PortfolioGrid from '../igtComponant/HomePage/PortfolioGrid';
import Testimonial from '../igtComponant/HomePage/Testimonial';
import ClientCarousel from '../igtComponant/HomePage/ClientCarousel';
import {Helmet} from "react-helmet";
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Awards from "../igtComponant/HomePage/Awards";

const Home = () => {

  useEffect(()=>{
    document.getElementsByTagName("body")[0].removeAttribute("class"); 
    document.body.className += ' homepage';
    AOS.init({ duration: 2000 });
  },[]);

  return (
    <>
      <Helmet>
          <title>IGTechso </title>
          <link rel="canonical" href={process.env.PUBLIC_URL}></link>
          <meta name="description" content="IGT" />
          <meta name="keywords" content="IGT" />
          <meta name="author" content="IGT" />
  
      </Helmet>  
      {/* <HomeSlider /> */}
      <IgtHomeSlider />
      <Process />
      <AboutUs />
      <ServicesSection />
      {/* <PortfolioGrid /> */}
      <Testimonial />
      <ClientCarousel />
      <Awards/>
    </>
  );
};
export default Home;

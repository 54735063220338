import { base_url_url } from '../Links';
import TestimonialSlider from '../TestimonialSlider'
const Testimonnials = () => {
    const myStyle = {
        /*backgroundImage: `url("${base_url_url}layer-20.png")`,*/
    };

    return (
        <section className='testimonials-wrapper' style={myStyle}>
            <div className='testimoialImage'>
                <img src={`${base_url_url}/Floor.png`} width="300px" />
            </div>

            <div className="testimonial-content" data-aos="fade-left" data-aos-once="true">
                <h3>TESTIMONIALS</h3>
                <h2>Happy Clients About Us</h2>
                <p className='subtitle'>Your 100% satisfaction is our ultimate goal</p>
                <TestimonialSlider />
            </div>
        </section>
    )
}
export default Testimonnials;
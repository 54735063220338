import { base_url_url } from './Links';
import { Link } from 'react-router-dom';
import TawkTo from 'tawkto-react';
import { useEffect } from 'react';

const Footer = () =>{
    const mystyle = {
        backgroundImage: `url("${base_url_url}footer.png")`,
    };

    const to_top = () =>{
        window.scrollTo({
            top:0, 
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        var tawk = new TawkTo("5dc12314e4c2fa4b6bda05ae", "default")
        tawk.onStatusChange((status) => 
        {
            console.log(status)
        })
    }, [])

    return(
        <>
            <section style={mystyle} className='footer'>
                <div className="container">
                    <div className='footerRow'>
                        <div className='contact-details'>
                            {/* <img src={`${base_url_url}logo-74.png`} alt="IGTechso Pvt. Ltd" title="IGTechso Pvt. Ltd" width="auto" height="auto"/> */}
                            <Link to="/" className='logo'><img src={`${base_url_url}Logo2.png`} alt="IGT" title="IGTechso Pvt. Ltd" width="191" height="auto"/></Link>
                            <p className='footerEmail'>
                                <a href="mailto:Update@igtechso.com" target="_blank" >Update@igtechso.com</a>
                            </p>

                            <p className='footerPhone'>
                                <a href="tel:+918770553894"  target="_blank" >+918770553894</a>
                            </p>

                            <div className="social">
                                <a href="https://www.facebook.com/IGTechso" 
                                rel="nofollow" target="_blank" className="button facebook">
                                    <i className="fa fa-facebook"></i>
                                    <div className="blurred">
                                        <span style={{"color":"transparent"}}>.</span>
                                        <span></span>
                                    </div>
                                </a>

                                <a href="https://www.linkedin.com/company/infinity-genesis-techso-private-limited/" 
                                rel="nofollow" target="_blank" className="button linkedin">
                                    <i className="fa fa-linkedin"></i>
                                    <div className="blurred">
                                        <span style={{"color":"transparent"}}>.</span>
                                        <span></span>
                                    </div>
                                </a>

                                <a href="https://www.instagram.com/igtechso/" 
                                rel="nofollow" target="_blank" className="button instagram">
                                    <i className="fa fa-instagram"></i>
                                    <div className="blurred">
                                        <span style={{"color":"transparent"}}>.</span>
                                        <span></span>
                                    </div> 
                                </a>

                                {/*
                                <a href="https://wa.me/+918770553894" 
                                rel="nofollow"
                                target="_blank" className="button">
                                    <i className="fa fa-whatsapp"></i>
                                    <div className="blurred">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a>

                                <a href="https://in.pinterest.com/avocationinfote/" 
                                rel="nofollow"
                                target="_blank" className="button">
                                    <i className="fa fa-envelope"></i>
                                    <div className="blurred">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </a>
                                */}


                            </div>

                            <svg className='bottom-filter'>
                                <defs >
                                    <filter id='goo'>
                                    <feGaussianBlur in='SourceGraphic' 
                                    stdDeviation='10' result='name'/>
                                    <feColorMatrix in='name' mode='matrix'
                                        values='1 0 0 0 0
                                                0 1 0 0 0 
                                                0 0 1 0 0
                                                0 0 0 30 -15 ' result='aab'/>
                                        <feBlend in='SourceGraphic' in2='aab'/>
                                    </filter>
                                </defs>
                            </svg>

                        </div>


                        <div className='contact-details footer-widget'>
                            <h3>Quick Links</h3>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/services">Our Services</Link></li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/contact">Contact us</Link></li>
                            </ul>
                        </div>

                        <div className='contact-details footer-widget'>
                            <h3>Services</h3>
                            <ul>
                                <li><Link to="/services">MERN Stack Development</Link></li>
                                <li><Link to="/services">MEAN Stack Development</Link></li> 
                                <li><Link to="/services">Hosting & Support</Link></li>
                                <li><Link to="/services">WordPress & WooCommerce</Link></li>
                                <li><Link to="/services">eCommerce Websites</Link></li>
                            </ul>
                        </div>

                        <div className='contact-details footer-widget'>
                            <h3>-</h3>
                            <ul>        
                                <li><Link to="/services">Web Development</Link></li>                        
                                <li><Link to="/services">Search Engine Optimisation</Link></li>
                                <li><Link to="/services">Android Application Development</Link></li>
                                <li><Link to="/services">IOS Application Development</Link></li>
                                <li><Link to="/about">Why IGTechso Pvt. Ltd.?</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className='footer-copyright'>
                        <p>Copyright © 2015-{(new Date().getFullYear())} <Link to="#">IGTechso Pvt. Ltd</Link>. - All Right Reserved</p>
                        <span className='to-top' onClick={to_top}><Link to="/#root"><span style={{"color":"transparent"}}>.</span></Link></span>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Footer;

import { base_url_url } from "../igtComponant/Links";
import { Link } from 'react-router-dom';
import "../services.css";
import { Helmet } from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import PortfolioItem from "./PortfolioItem";

const Portfolio = () => {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const port_type_1 = {
        backgroundImage: `url("${base_url_url}p1.jpg")`,
    };

    const port_type_2 = {
        backgroundImage: `url("${base_url_url}p2.jpg")`,
    };

    const port_type_3 = {
        backgroundImage: `url("${base_url_url}p3.jpg")`,
    };

    const port_type_4 = {
        backgroundImage: `url("${base_url_url}p4.jpg")`,
    };

    const port_type_5 = {
        backgroundImage: `url("${base_url_url}p5.jpg")`,
    };

    const port_type_6 = {
        backgroundImage: `url("${base_url_url}p6.jpg")`,
    };

    const [wordpress, setWordpress] = useState();
    const [shopify, setShopify] = useState();
    const [allBtn, setAllBtn] = useState('active');
    const [shopifyBtn, setShopifyBtn] = useState("");
    const [wordpressBtn, setWordpressBtn] = useState("");

    const filterItem = (filterItems) =>{
        if(filterItems === "all"){
            setShopify("");
            setWordpress("");
            setAllBtn("active");
            setShopifyBtn("");
            setWordpressBtn("");
        }

        if(filterItems === "wordpress"){
            setShopify("hidePortfolio");
            setWordpress("");
            setAllBtn("");
            setShopifyBtn("");
            setWordpressBtn("active");
        }

        if(filterItems === "shopify"){
            setShopify("");
            setWordpress("hidePortfolio");
            setAllBtn("");
            setShopifyBtn("active");
            setWordpressBtn("");
        }

    } 

   
    return (
        <>
            {/* <Helmet>
                <title>Portfolio | IGTechso </title>
                <link rel="canonical" href={`${process.env.PUBLIC_URL}/portfolio`}></link>
                <meta name="description" content="IGT" />
                <meta name="keywords" content="IGT" />
                <meta name="author" content="IGT" />  
            </Helmet>

            <section className="services-header ">
                <div className="bg">
                    <img src={`${base_url_url}service-bottom-left.png`} alt="Background Layer" title="Background Layer" width="auto" height="auto" data-aos="fade-right" data-aos-once="true"   className="bottom-left" />
                    <img src={`${base_url_url}service-top-right.png`} alt="Background Layer" title="Background Layer" width="auto" height="auto" data-aos="fade-left" data-aos-once="true"   className="top-right" />
                </div>
                <div className="container" data-aos="fade-up" data-aos-once="true">
                    <div className="row">
                        <div className="content-section">
                            <h2>Our Portfolio</h2>

                            <div className="portfolio-filter">
                                <ul>
                                    <li className={`filterList ${allBtn}`} onClick={()=>filterItem("all")} >All</li>
                                    <li className={`filterList ${wordpressBtn}`} onClick={()=>filterItem("wordpress")} >Wordpress</li>
                                    <li className={`filterList ${shopifyBtn}`} onClick={()=>filterItem("shopify")} >Shopify</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="portfolio-grid">
 
                        <PortfolioItem
                            category="Wordpress"
                            image="port-1.png"
                            title="Digi2b"
                            link="https://digi2b.co.uk/"
                            className={`type_1 ${wordpress}`} 
                            styleType={port_type_1} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-2.png"
                            title="Ecraft Media"
                            link="https://www.ecraftmedia.com/"
                            className={`type_2 ${wordpress}`} 
                            styleType={port_type_2} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-3.png"
                            title="Proxcars"
                            link="https://www.proxcars.com/"
                            className={`type_3 ${wordpress}`} 
                            styleType={port_type_3} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-4.png"
                            title="Jugoton Web Shop"
                            link="https://jugoton.net/"
                            className={`type_4 ${wordpress}`} 
                            styleType={port_type_4} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-5.png"
                            title="Motor City Body Works"
                            link="http://www.motorcitybodyworks.com.au/"
                            className={`type_5 ${wordpress}`} 
                            styleType={port_type_5} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-6.png"
                            title="Modern Pools"
                            link="http://www.modernpools.com.sg/"
                            className={`type_6 ${wordpress}`} 
                            styleType={port_type_6} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-7.png"
                            title="Bonnet Bay Baseball"
                            link="https://www.bonnetbaybaseball.com.au/"
                            className={`type_1 ${wordpress}`} 
                            styleType={port_type_1} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-8.png"
                            title="Robina Function Centre"
                            link="http://robinafunctioncentre.com.au/"
                            className={`type_2 ${wordpress}`} 
                            styleType={port_type_2} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-9.png"
                            title="Family Foot Clinicis"
                            link="https://familyfootclinic.com.au/"
                            className={`type_3 ${wordpress}`} 
                            styleType={port_type_3} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-10.png"
                            title="Extreme Vision Welding"
                            link="https://extremevisionwelding.com.au/"
                            className={`type_4 ${wordpress}`} 
                            styleType={port_type_4} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-11.png"
                            title="Al Mespar"
                            link="http://www.almespar.com/"
                            className={`type_5 ${wordpress}`} 
                            styleType={port_type_5} />

                        <PortfolioItem
                            category="Wordpress"
                            image="port-12.png"
                            title="Choice Suspension"
                            link="http://www.choicesuspension.com.au/"
                            className={`type_6 ${wordpress}`} 
                            styleType={port_type_6} />
                        
                        <PortfolioItem
                            category="Shopify"
                            image="port-13.png"
                            title="Rai Vitamins"
                            link="https://www.raivitamins.com.au/"
                            className={`type_1 ${shopify}`} 
                            styleType={port_type_1} />

                        <PortfolioItem
                            category="Shopify"
                            image="icosiest.png"
                            title="Cosiest"
                            link="https://icosiest.com/"
                            className={`type_2 ${shopify}`} 
                            styleType={port_type_2} />
                                                
                        <PortfolioItem
                            category="Shopify"
                            image="hhmsuper.png"
                            title="HHM Super"
                            link="https://hhmsuper.com/"
                            className={`type_3 ${shopify}`} 
                            styleType={port_type_3} />

                        <PortfolioItem
                            category="Shopify"
                            image="tonyferguson.png"
                            title="Tony Ferguson"
                            link="https://tonyferguson.com/"
                            className={`type_4 ${shopify}`} 
                            styleType={port_type_4} />

                        <PortfolioItem
                            category="Shopify"
                            image="cawlife.png"
                            title="Caw Life"
                            link="https://cawlife.com/"
                            className={`type_5 ${shopify}`} 
                            styleType={port_type_5} />

                        <PortfolioItem
                            category="Shopify"
                            image="hazeloptics.png"
                            title="Hazel Optics"
                            link="https://hazeloptics.com/"
                            className={`type_6 ${shopify}`} 
                            styleType={port_type_6} />
                            
                        <PortfolioItem
                            category="Shopify"
                            image="motorsportsparts.png"
                            title="Motor Sports Parts"
                            link="https://www.motorsportsparts.online/"
                            className={`type_1 ${shopify}`} 
                            styleType={port_type_1} />
                        
                        <PortfolioItem
                            category="Shopify"
                            image="inhandgo.png"
                            title="IoT  In Hand"
                            link="https://inhandgo.com/"
                            className={`type_2 ${shopify}`} 
                            styleType={port_type_2} />
                             
                        <PortfolioItem
                            category="Shopify"
                            image="huayiknit.png"
                            title="Jiangsu Huayi Knitting"
                            link="https://www.huayiknit.com/"
                            className={`type_3 ${shopify}`} 
                            styleType={port_type_3} />
                             
                        <PortfolioItem
                            category="Shopify"
                            image="pearlgiftbox.png"
                            title="Pearl Packaging"
                            link="https://pearlgiftbox.myshopify.com/"
                            className={`type_4 ${shopify}`} 
                            styleType={port_type_4} />
                             
                        <PortfolioItem
                            category="Shopify"
                            image="brotree.png"
                            title="Brotree"
                            link="https://www.brotree.com/"
                            className={`type_5 ${shopify}`} 
                            styleType={port_type_5} />
                             
                        <PortfolioItem
                            category="Shopify"
                            image="neonstrength.png"
                            title="Neon Strength"
                            link="https://neonstrength.com/"
                            className={`type_6 ${shopify}`} 
                            styleType={port_type_6} />
                             
                        <PortfolioItem
                            category="Shopify"
                            image="glolift.png"
                            title="Glo Lift"
                            link="https://glolift.com/"
                            className={`type_1 ${shopify}`} 
                            styleType={port_type_1} />
                              
                    </div>
                </div>
            </section> */}
        </>
    )
}
export default Portfolio;

import React from 'react'
import './Slide2.css'; 
import { base_url_url } from '../Links';
import {Link} from 'react-router-dom'

const Slide2 = () => {
    return (
        <div className='animatedSlider-wrapper' id="slide-2">
            <div className='sliderBanner'>
               <img src={`${base_url_url}banner-102.png`} className='banner-1' alt="" title="" width="100%" height="auto"/>    
               <img src={`${base_url_url}banner-101.png`} className='banner-2' alt="" title="" width="100%" height="auto"/>    
            </div>
            <div className='container'> 
                <div className='slider-box'>
                    <div>
                        <img src={`${base_url_url}banner-01.png`} alt="" title="" width="auto" height="auto"/>    
                    </div>
                    <div>
                        <h2>MEAN Stack Development</h2>
                        <p>We at IGTechso have a dedicated process which'll help you with step by step website development and project management.</p>
                        <a className="btn-1" href="/portfolio"><span></span><span></span><span></span>Explore More</a>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Slide2

const DropAnimation = () =>{
    return(
        <>
            <span className="btn-3">
			<span className="drops">
    			<span className="drop1"></span>
    			<span className="drop2"></span>  
  			</span>
			</span>
        </>
    )
}

export default DropAnimation;
import { Link } from 'react-router-dom'
import {  base_url_url } from './Links'
import { useEffect } from 'react'


const Slide1 = () =>{
    const mystyle = {
        backgroundImage: `url("${base_url_url}slide-1.jpg")`,
        position: 'relative',
        paddingTop: '174px',
        backgroundSize: 'cover',
        paddingBottom: '74px',
    };
    
    

    return(
        <>
        <div className="slider-content" style={mystyle}>
          <div className="container">
              <div className='contents-side'>
              <h2 data-aos="fade-right" data-aos-once="true"><span>OVER 5000 </span> <br />
              
              <span>WEBSITES Go Ahead </span></h2>

              <h4 data-aos="fade-right" data-aos-once="true">With this much experience, you can <br />trust us to take care of your<br /> website project.</h4>

              <Link to="/" className="btn-1" data-aos="fade-right" data-aos-once="true"><span></span><span></span><span></span>Explore Now</Link>
              </div>
              

              <div className="image-section">
                  <img src={`${base_url_url}layer.png`}  alt="" className="img-1"/>
                 <img src={`${base_url_url}image.png`}  alt="" className="img-1"/>
              </div>
          </div>
       </div>
        </>
    )
}

export default Slide1;
import { base_url_url } from './Links';
import { Link, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DropAnimation from './DropAnimation';


const Header = () => {

    const [scroll, setScroll] = useState(false);
    const [popup, setPopup] = useState(false);
    const [activeMobileMenu, setMobileMenu] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const openMenuBar = () => {
        setMobileMenu(true);
    }

    const closeMenuBar = () => {
        setMobileMenu(false);
    }

    const removeBodyClass = () => {
        document.getElementsByTagName("body")[0].removeAttribute("class");
        window.scrollTo(0, 0);
    } 

    return (
        <>
            <section id="header" className={scroll ? "fixed-header" : ""}  >
                <header className='header-section' id={activeMobileMenu ? "activeMenu" : ""}>
                    <div className="container" >
                        <div className="header-content" onClick={removeBodyClass}>
                            <Link to="/" className='logo'><img src={`${base_url_url}Logo2.png`} alt="IGT" title="IGTechso Pvt. Ltd" width="191" height="auto"/></Link>
                            {/* <Link to="/" className='logo'><img src='/images/Logo2.png' alt="IGT" title="IGTechso Pvt. Ltd" width="200" height="auto"/></Link> */}
                            <span className='menu-opner'>
                                <label id="menu-label">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </label>
                                <i className='fa fa-bars' onClick={openMenuBar}></i>
                                <i className='fa fa-times' onClick={closeMenuBar}></i>
                            </span>
                            <nav>
                                <ul onClick={closeMenuBar}>
                                    <li><NavLink to="/" className="menu-item"> <DropAnimation /><span className='menu-text'>Home</span></NavLink></li>
                                    <li><NavLink to="/services" className="menu-item"><DropAnimation /><span  className='menu-text'>Our Services</span></NavLink></li>
                                    <li><a href="https://www.youtube.com/@igtechso/featured" target='BLANK' className="menu-item"><DropAnimation /><span className='menu-text'>Portfolio</span></a></li>
                                    <li><NavLink to="/about" className="menu-item"><DropAnimation /><span className='menu-text'>About Us</span></NavLink></li>
                                    <li><NavLink to="/contact" className="menu-item"><DropAnimation /><span className='menu-text'>Contact us</span></NavLink></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </header>
            </section>

            <div className={`popup ${popup ? "activePopup" : ""}`} onClick={()=>setPopup(false)}>
                <div className='innerPopup' onClick={(e)=>{e.stopPropagation(); setPopup(true)}}>
                    <h1 style={{"display":"none"}}>Welcome to IGTechso Pvt. Ltd</h1>
                    <a onClick={(e)=>{e.stopPropagation(); setPopup(false)}} className='close'><span></span><span></span></a>
                    <h2>Choose Enquiry</h2> 
                    <Link onClick={(e)=>{e.stopPropagation(); setPopup(false)}} to='/website-enquiry'   className='btn-01'>Website Enquiry Form</Link>
                    <Link onClick={(e)=>{e.stopPropagation(); setPopup(false)}} to='/website-update'   className='btn-02' >Webiste Update From</Link>
                    <Link onClick={(e)=>{e.stopPropagation(); setPopup(false)}} to='/career'   className='btn-02' >Career From</Link>
                </div>
            </div>
        </>

    )
}
export default Header;
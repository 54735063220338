import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { base_url_url } from '../Links'

const PortfolioGrid = () => {
   const settings1 = {
      dots: true,
      infinite: true,
      speed: 900,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
         {
            breakpoint: 1050,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 3,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   };

   const layer_1 = {
      backgroundImage: `url("${base_url_url}clinet-1.png")`
   }

   const layer_2 = {
      backgroundImage: `url("${base_url_url}clinet-2.png")`
   }

   const layer_3 = {
      backgroundImage: `url("${base_url_url}clinet-3.png")`
   }

   const layer_4 = {
      backgroundImage: `url("${base_url_url}clinet-4.png")`
   }

   const layer_5 = {
      backgroundImage: `url("${base_url_url}clinet-5.png")`
   }

   return (
      <div className="client-slider">
         <h2>Our Valuable Clients</h2>
         <h3 className="subtitle">Happy clients make us look Awesome</h3>
         <div >
            <Slider {...settings1}>
               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true" data-aos-delay="0">
                     <span style={layer_1}>
                        <img src={`${base_url_url}a1.png`} alt="" title="" width="auto" height="auto"  />
                     </span>

                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true" data-aos-delay="300">
                     <span style={layer_2}>
                        <img src={`${base_url_url}a2.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true" data-aos-delay="600">
                     <span style={layer_3}>
                        <img src={`${base_url_url}a3.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true" data-aos-delay="900">
                     <span style={layer_4}>
                        <img src={`${base_url_url}a4.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true" data-aos-delay="1200">
                     <span style={layer_5}>
                        <img src={`${base_url_url}a5.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true">
                     <span style={layer_1}>
                        <img src={`${base_url_url}a6.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true">
                     <span style={layer_2}>
                        <img src={`${base_url_url}a7.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true">
                     <span style={layer_3}>
                        <img src={`${base_url_url}a8.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

               <div>
                  <div className="clientLogo" data-aos="fade-up" data-aos-once="true">
                     <span style={layer_4}>
                        <img src={`${base_url_url}a9.png`} alt="" title="" width="auto" height="auto" />
                     </span>
                  </div>
               </div>

            </Slider>
         </div>

         <svg className="portfolio-filter2" width="0" height="0">
            <defs>
               <clipPath id="maskRect2">
                  <path id="curve" d="M58.4,-52.4C73.4,-43.4,81.7,-21.7,79.9,-1.8C78.1,18.1,66.2,36.2,51.2,40.7C36.2,45.1,18.1,35.9,0.9,35C-16.2,34,-32.5,41.4,-47,36.9C-61.6,32.5,-74.5,16.2,-73.4,1.1C-72.4,-14.1,-57.3,-28.2,-42.8,-37.2C-28.2,-46.2,-14.1,-50.1,3.8,-54C21.7,-57.8,43.4,-61.5,58.4,-52.4Z" />
               </clipPath>
            </defs>
         </svg>
 

      </div>
   )
}

export default PortfolioGrid;
import React, { useRef, useEffect, useState } from 'react';
import { base_url_url } from "../igtComponant/Links";
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Helmet } from "react-helmet";

const Contact = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    const form = useRef();
    const [formmessage,setFormMessage] = useState("");
    

    return (
        <>
            <Helmet>
                <title>Contact Us | IGTechso </title>
                <link rel="canonical" href={`${process.env.PUBLIC_URL}/contact`}></link>
                <meta name="description" content="IGT" />
                <meta name="keywords" content="IGT" />
                <meta name="author" content="IGT" /> 
            </Helmet>
           
            <section className="services-header contact">
                <div className="bg">
                    <img src={`${base_url_url}contactUsLeft.png`} alt="Background Layer" title="Background Layer" width="auto" height="auto" data-aos="fade-up" data-aos-delay="700"    className="bottom-left" />
                    <img src={`${base_url_url}service-top-right.png`} alt="Background Layer" title="Background Layer" width="auto" height="auto" data-aos="fade-left" data-aos-delay="600"   className="top-right" />
                </div>
                 {/*
                <div className="container" data-aos="fade-up" data-aos-delay="800" alt="">
                    <div className="row">
                        <div className="content-section">
                            

                            <form ref={form} onSubmit={sendEmail} className='contact-form'>
                            <h2>Get In Touch</h2>
                            <p>Please feel free to contact us regarding any website design <br />
                                that you need to be created.</p>
                                <div className='three-column'>
                                    <input type="text" name="from_name" placeholder="Name" required />  
                                    <input type="email" name="user_email" placeholder="Email" required /> 
                                </div>
                                <input type="text" name="from_phone" placeholder="Phone" required /> <br />
                                <textarea name="message" placeholder="Details" /><br />
                                <button type="submit" value="Submit Now" className='submit-btn' >SUBMIT NOW</button>
                                <p className='message'>{formmessage}</p>
                            </form>
                        </div>
                    </div>
                </div> 
                */}
            </section>  
            
            <section className='contact-details'  data-aos="fade-up" >
                <div className='container'> 
                    <h2>Have Any Questions?</h2>
                    <p>Please feel free to contact us regarding any website design that you need created. If you’re looking to build a website for your business or even just to chat, than we are here for you. At IGTechso we reply prompt to all messages and calls and help you achieve your goals and needs.</p>
                    <div className='contact-grid'>
                        <div className='contact-grid-item' data-aos="fade-up" data-aos-delay="0" data-aos-once="true">
                             <div className='contact-detalsbox'>
                                <img src={`${base_url_url}OBJECTS.png`} alt="Online Support" title="Online Support" width="auto" height="auto"/> 
                                <a href='mailto:Update@igtechso.com'>Update@igtechso.com</a>
                                <strong>Online Support</strong>
                             </div>
                        </div>    

                        <div className='contact-grid-item' data-aos="fade-up" data-aos-delay="600" data-aos-once="true">     
                             <div className='contact-detalsbox'>
                                <img src={`${base_url_url}whatsapp.png`} alt="Whatsapp" title="Whatsapp" width="auto" height="auto"/> 
                                <a href="https://wa.me/+918770553894" target="_blank">+91 8770 553894</a>
                                <strong>Whatsapp</strong>
                             </div>   
                        </div>

                        <div className='contact-grid-item' data-aos="fade-up" data-aos-delay="600" data-aos-once="true">     
                             <div className='contact-detalsbox'>
                                <img src={`${base_url_url}linked.png`} alt="Linkedin" title="Linkedin" width="auto" height="auto"/> 
                                <a href="https://www.linkedin.com/company/infinity-genesis-techso-private-limited" target="_blank">Infinity Genesis Techso</a>
                                <strong>Linkedin</strong>
                             </div>   
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Contact;
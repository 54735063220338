import { base_url_url } from '../Links';
import './Process.css';
import { ProcessShapOne, ProcessShapTwo, ProcessShapThree, ProcessShapFour } from '../Shape/ProcessShape';

const Process = () => {
    return (
        <>
            <section id="process">
                <div className="container">
                    <h2 data-aos="fade-up" data-aos-once="true" className="heading">Unique Web Development Process</h2>
                    <p data-aos="fade-up" data-aos-once="true" >We at IGTechso have a dedicated process which'll help you with step <br /> by step website development and project management.</p>
                    {/*
                <div className='infographic-section'>
                    <div className='infographic-item' data-aos="fade-up" data-aos-once="true">
                        <div className='infographic'>
                            <div>
                                <div>
                                    <img src={`${base_url_url}plan.png`} alt=""/>
                                </div>
                            </div>
                        </div>
                        <h4>PLANNING</h4>
                    </div>

                    <div className='infographic-item' data-aos="fade-up" data-aos-once="true">
                        <div className='infographic'>
                            <div>
                                <div>
                                    <img src={`${base_url_url}design.png`} alt=""/>
                                </div>
                            </div>
                        </div>
                        <h4>DESIGN</h4>
                    </div>

                    <div className='infographic-item' data-aos="fade-up" data-aos-once="true">
                        <div className='infographic'>
                            <div>
                                <div>
                                    <img src={`${base_url_url}development.png`} alt=""/>
                                </div>
                            </div>
                        </div>
                        <h4>DEVELOPMENT</h4>
                    </div>

                    <div className='infographic-item' data-aos="fade-up" data-aos-once="true">
                        <div className='infographic'>
                            <div>
                                <div>
                                    <img src={`${base_url_url}launch.png`} alt=""/>
                                </div>
                            </div>
                        </div>
                        <h4>LAUNCH</h4>
                    </div>

                    <div className='infographic-item' data-aos="fade-up" data-aos-once="true">
                        <div className='infographic'>
                            <div>
                                <div>
                                    <img src={`${base_url_url}maintatnance.png`} alt=""/>
                                </div>
                            </div>
                        </div>
                        <h4>MAINTENANCE</h4>
                    </div>
                </div>
                
                <div className="cntNewProcess">
                    <div className="cntProcessItem" data-aos="fade-up" data-aos-once="true" data-aos-delay="0">
                        <ProcessShapOne />
                        <div className="processItemContent">
                            <i className='fa fa-file-text-o'></i>
                            <h4>PLANNING</h4>
                        </div>
                    </div>

                    <div className="cntProcessItem" data-aos="fade-up" data-aos-once="true" data-aos-delay="300">
                        <ProcessShapTwo />
                        <div className="processItemContent">
                            <i className='fa fa-desktop'></i>
                            <h4>DESIGN</h4>
                        </div>
                    </div>

                    <div className="cntProcessItem" data-aos="fade-up" data-aos-once="true" data-aos-delay="600">
                        <ProcessShapThree />
                        <div className="processItemContent">
                            <i className='fa fa-cogs'></i>
                            <h4>DEVELOPMENT</h4>
                        </div>
                    </div>

                    <div className="cntProcessItem" data-aos="fade-up" data-aos-once="true" data-aos-delay="900">
                        <ProcessShapFour />
                        <div className="processItemContent">
                            <i className='fa fa-rocket'></i>
                            <h4>LAUNCH</h4>
                        </div>
                    </div>

                    <div className="cntProcessItem" id="fifth-process" data-aos="fade-up" data-aos-delay="1200" data-aos-once="true">
                        <ProcessShapOne />
                        <div className="processItemContent">
                            <i className='fa fa-wrench'></i>
                            <h4>MAINTENANCE</h4>
                        </div>
                    </div>

                </div> */}

                    <div className="cntNewProcess-2" data-aos="fade-up" data-aos-once="true">
                        <div className='processBox-1'>
                            <div className='innerProcessBox'>
                                <i className='fa fa-file-text-o'></i>
                                <h3>PLANNING</h3>
                            </div>
                        </div>
                        <div className='processBox-1'>
                            <div className='innerProcessBox'>
                                <i className='fa fa-desktop'></i>
                                <h3>Design</h3>
                            </div>
                        </div>
                        <div className='processBox-1'>
                            <div className='innerProcessBox'>
                                <i className='fa fa-cogs'></i>
                                <h3>DEVELOPMENT</h3>
                            </div>
                        </div>
                        <div className='processBox-1'>
                            <div className='innerProcessBox'>
                                <i className='fa fa-rocket'></i>
                                <h3>LAUNCH</h3>
                            </div>
                        </div>
                        <div className='processBox-1'>
                            <div className='innerProcessBox'>
                                <i className='fa fa-wrench'></i>
                                <h3>MAINTENANCE</h3>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Process;
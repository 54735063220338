import {base_url_url} from "../igtComponant/Links";
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import  "../about.css";
import {Helmet} from "react-helmet";
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const About =() =>{
    
    useEffect(()=>{
        document.body.className +=' dark-header';
        AOS.init({ 
            duration: 2000,
         });
    },[]);

    const aboutTop = {
        backgroundImage:`url("${base_url_url}bgabout.jpg")`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat"
    }

    const agencyBG={
        backgroundImage:`url("${base_url_url}feature-step-bg1.png")`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat"
    }

    return(
        <>
 
        <Helmet>
            <title>About Us | IGTechso </title>
            <link rel="canonical" href={`${process.env.PUBLIC_URL}/about`}></link>
            <meta name="description" content="IGT" />
            <meta name="keywords" content="IGT" />
            <meta name="author" content="IGT" />  
        </Helmet>  

        <section style={aboutTop} className="aboutUs">
            <div className="container">
                <h2  data-aos="fade-right" data-aos-delay="300">The IGTechso<br />Experience</h2>
                <p  data-aos="fade-right" data-aos-delay="500">We love what we do. And are inspired to give <br />you the best experience we can.</p>
                <Link  data-aos="fade-up" to="/contact" className="btn-1"><span></span><span></span><span></span>Contact Us</Link>
                <img src={`${base_url_url}about.png`} alt="The IGTechso Experience" title="The IGTechso Experience" width="auto" height="auto"/>
            </div>
        </section>

        <section className="our-agency about-us-content">
            <div className="container">
                <div className="row">
                    <div className="image-section">
                        <div className="oa-left-content" >
                        <div className="bg" style={agencyBG}></div>
                            <p data-aos="fade-right" data-aos-once="true"><span>96%</span> CLIENT RETENTION</p>
                            <p data-aos="fade-right" data-aos-once="true"><span>{(new Date().getFullYear())-2013} </span>YEARS OF SERVICE</p>
                            <p data-aos="fade-right" data-aos-once="true"><span>12+</span> PROFESSIONALS</p>
                            <p data-aos="fade-right" data-aos-once="true"><span>4860+</span> SATISFIED CLIENTS</p>
                        </div>
                    </div>
                    <div className="content-section" data-aos="fade-left" data-aos-once="true">
                        <h2>About Our <span className="highlight-theme-color">Agency</span></h2>
                        <p>We are known for our Web development expertise and customer service with a personal touch. It’s a blend that we’ve been cultivating for more than half a decade.Something we’ve shared with more than 5000 businesses around Different Countries of Many continents.</p>
                        <p>We’re passionate about web design, crafting savvy digital marketing campaigns and helping clients showcase their business on the main stage. And our experience has taught us that there’s more to it than that – it’s the little things that make the difference. Our team of designers and developers are here to deliver their best to help you bring your dream to life or take your business to the next level.</p>
                    </div>
                </div>
            </div>
        </section>


        <section className="history about-us-content">
            <div className="container">
                <div className="row">
                    <div className="content" data-aos="fade-right" data-aos-once="true">
                        <h3>Jan, 2013</h3> 
                        <h2>Foundation of the Company</h2>
                        <p>In 2013, IGTechso founders Akhilesh Tiwari saw a need to create a web design company with a difference. – One that empowered business owners to embrace the internet. IGTechso has since transformed into an industry Expert and are now proud cheerleaders for entrepreneurship and watching little things grow into big things.</p>
                    </div> 

                    <div className="image-section" data-aos="fade-left" data-aos-once="true">
                        <img src={`${base_url_url}1234.jpg`}  alt="Foundation of the Company" title="Foundation of the Company" width="auto" height="auto"/>
                    </div>
                </div>
            </div>
        </section>
        
        </> 
    )
}
export default About;  
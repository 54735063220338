import Home from "./pages/Home";
import About from "./pages/About";
import Services from './pages/Services';
import Portfolio from './pages/Portfolio';
import Contact from "./pages/Contact";
import Career from "./pages/career/";
import SingleCareer from "./pages/career/SingleCareer";
import Sample from "./pages/Sample";
import Blog from "./pages/Blog";
import SingleBlog from "./pages/SingleBlog";
import PageNotFound from "./pages/PageNotFound";
import WebsiteUpdate from './pages/WebsiteUpdate';
//import { HashRouter as Router, Routes, Route } from "react-router-dom"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Header from './igtComponant/Header';
import Footer from './igtComponant/Footer';
import WebsiteEnquiry from "./pages/WebsiteEnquiry";



const App = () => {
 /*
  window.onbeforeunload = function() {
    window.scrollTo(0, 0);
 }
   <base href="https://www.w3schools.com/" target="_blank">

 */


  return (
    <>
      <Router /*basename="/"  */ basename={process.env.PUBLIC_URL}>
      <Header />
        <Routes>
          <Route path="/" index element={<Home />}></Route>
          <Route path="/about" index element={<About />}></Route>
          <Route path="/services" index element={<Services />}></Route>
          <Route path="/portfolio" index element={<Portfolio />}></Route>
          <Route path="/contact" index element={<Contact />}></Route>
          {/* <Route path="/blog" index element={<Blog />}></Route>
          <Route path="/sample" index element={<Sample />}></Route>
          <Route path="/website-enquiry" index element={<WebsiteEnquiry />}></Route>
          <Route path="/career" index element={<Career />}></Route>
          <Route path="/website-update" index element={<WebsiteUpdate />}></Route>
          <Route path="/career/:id" index element={<SingleCareer />}></Route>
          <Route path="/blog/:id" element={<SingleBlog />}></Route> */}
          <Route path="*" element={<PageNotFound />}></Route>
        </Routes> 
        <Footer /> 
      </Router>
    </>
  );
};

export default App;

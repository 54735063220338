import React from "react";
import { Link } from "react-router-dom";

const Awards = () => {
  return (
    <div className="container">
      <div className="awards">
        <h2>Awards & Recognition</h2>
        <div className="inner_awards">
          <div className="awards_items">
            <div>
              <Link
                to="https://www.trustpilot.com/review/igtechso.com"
                target="blank"
              >
                <img src="/images/Trustpilot_logo.png" />
              </Link>
            </div>
          </div>
          <div className="awards_items">
            <div>
              <Link
                to="https://www.designrush.com/agency/profile/igtechso-pvt-ltd"
                target="blank"
              >
                <img
                  className="dasignrush_image"
                  src="/images/designrush.png"
                />
              </Link>
            </div>
          </div>
          <div className="awards_items">
            <div>
              <Link
                to="https://clutch.co/profile/infinity-genesis-techso"
                target="black"
              >
                <img className="clutch_image" src="/images/clutch.jpg" />
              </Link>
            </div>
          </div>
          <div className="awards_items">
            <div>
              <Link
                to="https://www.glassdoor.co.in/Reviews/Employee-Review-Infinity-Genesis-Techso-RVW87337945.htm"
                target="blank"
              >
                <img src="/images/glassdore.jpg" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;
